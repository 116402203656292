//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
    }
  }

.visually-hidden {
  display: none !important;
}
