/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
// @import "variables-dark"; (dark-theme)
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/dragula";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-editors";
@import "custom/plugins/responsive-table";
@import "custom/plugins/chartist";
@import "custom/plugins/apexcharts";
@import "custom/plugins/google-map";
@import "custom/plugins/angular-material";
@import "custom/plugins/toastr";
@import "custom/plugins/perfect-scrollbar";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/chat";
@import "custom/pages/crypto";
@import "custom/pages/custom";

//extra Css for pages
@import "src/styles.scss" ;


// rtl
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";


// custom scss
//  form wizard
aw-wizard {
    .steps-indicator{
        .label {
            color: $text-muted !important;
        }
        .current {
            .step-indicator{
                color: $primary !important;
                border-color: $primary !important;
            }
        }
    }
}
