//
// _buttons.scss
//

button,a {
  outline: none !important;
}

.btn {
	i {
		vertical-align: middle !important;
	}
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-success {
  background: rgba(67,
      199,
      151, 1) !important;
  //border: none !important;
  border-color: rgba(67,
      199,
      151, 1) !important;
}

.btn-danger {
  background: rgba(249,
      11,
      11, 1) !important;
  //border: none !important;
  border-color: rgba(249,
      11,
      11, 1) !important;
}

.btn-warning {
  background: rgba(241,
      180,
      76, 1) !important;
  //border: none !important;
  border-color: rgba(241,
      180,
      76, 1) !important;
}

.btn-info {
  background: rgba(80,
      165,
      241, 1) !important;
  //border: none !important;
  border-color: rgba(80,
      165,
      241, 1) !important;
}
