/*
  TODO: Remove important flags after this bug if fixed:
  https://github.com/angular/flex-layout/issues/381
*/

// perfect-scrollbar {
//   position: relative;
//   display: block;
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   max-width: 100%;
//   max-height: 100%;
// }

// perfect-scrollbar[hidden] {
//   display: none;
// }

// perfect-scrollbar[fxflex] {
//   display: flex;
//   flex-direction: column;
//   height: auto;
//   min-width: 0;
//   min-height: 0;

//   -webkit-box-direction: column;
//   -webkit-box-orient: column;
// }

// perfect-scrollbar[fxflex] > .ps {
//   -ms-flex: 1 1 auto;

//   flex: 1 1 auto;
//   width: auto;
//   height: auto;
//   min-width: 0;
//   min-height: 0;

//   -webkit-box-flex: 1;
// }

// perfect-scrollbar[fxlayout] > .ps,
// perfect-scrollbar[fxlayout] > .ps > .ps-content {
//   display: flex;

//   -ms-flex: 1 1 auto;

//   flex: 1 1 auto;
//   flex-direction: inherit;
//   align-items: inherit;
//   align-content: inherit;
//   justify-content: inherit;
//   width: 100%;
//   height: 100%;

//   -webkit-box-align: inherit;
//   -webkit-box-direction: inherit;
//   -webkit-box-flex: 1;
//   -webkit-box-orient: inherit;
//   -webkit-box-pack: inherit;
// }

// perfect-scrollbar[fxlayout='row'] > .ps,
// perfect-scrollbar[fxlayout='row'] > .ps > .ps-content, {
//   flex-direction: row !important;

//   -webkit-box-direction: row !important;
//   -webkit-box-orient: row !important;
// }

// perfect-scrollbar[fxlayout='column'] > .ps,
// perfect-scrollbar[fxlayout='column'] > .ps > .ps-content {
//   flex-direction: column !important;

//   -webkit-box-direction: column !important;
//   -webkit-box-orient: column !important;
// }

// perfect-scrollbar > .ps {
//   position: static;

//   display: block;
//   width: 100%;
//   height: 100%;
//   max-width: 100%;
//   max-height: 100%;
// }

// perfect-scrollbar > .ps textarea {
//   -ms-overflow-style: scrollbar;
// }

// perfect-scrollbar > .ps > .ps-overlay {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;

//   display: block;
//   overflow: hidden;

//   pointer-events: none;
// }

// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-top,
// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-left,
// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-right,
// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-bottom {
//   position: absolute;

//   opacity: 1;

//   transition: opacity 300ms ease-in-out;
// }

// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-top,
// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-bottom {
//   left: 0;

//   min-width: 100%;
//   min-height: 24px;
// }

// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-left,
// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-right {
//   top: 0;

//   min-width: 24px;
//   min-height: 100%;
// }

// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-top {
//   top: 0;
// }

// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-left {
//   left: 0;
// }

// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-right {
//   right: 0;
// }

// perfect-scrollbar > .ps > .ps-overlay .ps-indicator-bottom {
//   bottom: 0;
// }

// perfect-scrollbar > .ps.ps--active-y > .ps__rail-y {
//   top: 0 !important;
//   right: 0 !important;
//   left: auto !important;

//   width: 10px;

//   cursor: default;

//   transition:
//     width 200ms linear,
//     opacity 200ms linear,
//     background-color 200ms linear;
// }

// perfect-scrollbar > .ps.ps--active-y > .ps__rail-y:hover,
// perfect-scrollbar > .ps.ps--active-y > .ps__rail-y.ps--clicking {
//   width: 15px;
// }

// perfect-scrollbar > .ps.ps--active-x > .ps__rail-x {
//   top: auto !important;
//   bottom: 0 !important;
//   left: 0 !important;

//   height: 10px;

//   cursor: default;

//   transition:
//     height 200ms linear,
//     opacity 200ms linear,
//     background-color 200ms linear;
// }

// perfect-scrollbar > .ps.ps--active-x > .ps__rail-x:hover,
// perfect-scrollbar > .ps.ps--active-x > .ps__rail-x.ps--clicking {
//   height: 15px;
// }

// perfect-scrollbar > .ps.ps--active-x.ps--active-y > .ps__rail-y {
//   margin: 0 0 10px;
// }

// perfect-scrollbar > .ps.ps--active-x.ps--active-y > .ps__rail-x {
//   margin: 0 10px 0 0;
// }

// perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y,
// perfect-scrollbar > .ps.ps--scrolling-x > .ps__rail-x {
//   opacity: 0.9;

//   background-color: #eee;
// }

// perfect-scrollbar.ps-show-always > .ps.ps--active-y > .ps__rail-y,
// perfect-scrollbar.ps-show-always > .ps.ps--active-x > .ps__rail-x {
//   opacity: 0.6;
// }

// perfect-scrollbar.ps-show-active > .ps.ps--active-y > .ps-overlay:not(.ps-at-top) .ps-indicator-top {
//   opacity: 1;

//   background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
// }

// perfect-scrollbar.ps-show-active > .ps.ps--active-y > .ps-overlay:not(.ps-at-bottom) .ps-indicator-bottom {
//   opacity: 1;

//   background: linear-gradient(to top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
// }

// perfect-scrollbar.ps-show-active > .ps.ps--active-x > .ps-overlay:not(.ps-at-left) .ps-indicator-left {
//   opacity: 1;

//   background: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
// }

// perfect-scrollbar.ps-show-active > .ps.ps--active-x > .ps-overlay:not(.ps-at-right) .ps-indicator-right {
//   opacity: 1;

//   background: linear-gradient(to left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
// }

// perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-y > .ps-overlay.ps-at-top .ps-indicator-top {
//   background: linear-gradient(to bottom, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
// }

// perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-y > .ps-overlay.ps-at-bottom .ps-indicator-bottom {
//   background: linear-gradient(to top, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
// }

// perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-x > .ps-overlay.ps-at-left .ps-indicator-left {
//   background: linear-gradient(to right, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
// }

// perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-x > .ps-overlay.ps-at-right .ps-indicator-right {
//   background: linear-gradient(to left, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
// }

// perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-y > .ps-overlay.ps-at-top .ps-indicator-top.ps-indicator-show,
// perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-y > .ps-overlay.ps-at-bottom .ps-indicator-bottom.ps-indicator-show,
// perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-x > .ps-overlay.ps-at-left .ps-indicator-left.ps-indicator-show,
// perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-x > .ps-overlay.ps-at-right .ps-indicator-right.ps-indicator-show {
//   opacity: 1;
// }

.ps__rail-x,
.ps__rail-y {
  opacity: 0.4 !important;

  .ps__thumb-y:hover,
  .ps__thumb-x:hover {
    cursor: pointer !important;
    opacity: 0.6 !important;
    background: #999 !important;
  }
}


