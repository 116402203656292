.app-loading-bar {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9999;
}

.md-drppicker {
  top:0;
  right: 0;
}

.btn-no-effect {
  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.ng-value-disabled {
  color: #7D7C8D !important;
}

.filter-dropdown {
  position: relative;
  min-width: 200px;

  .btn {
    height: 38px !important;
  }
}

.search-box {
  position: relative;
  .filter-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 0px;
    line-height: 38px;
  }

  .search-icon {
    font-size: 15px;
    position: absolute;
    left: 10px;
    top: 0;
    line-height: 38px;
  }

  .information-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 38px;
  }

  input {
    padding-left: 30px !important;
    border-radius: 50px !important;
    line-height: 38px;
    height: 38px;
  }
}


.filter-custom-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 9px;
    line-height: 38px;
    z-index: 99;
    color: #999;
  }

  .pac-container {
    z-index: 9999 !important;
}


.fc-unthemed .fc-toolbar .fc-button {
    color: #74788d;
    background: 0 0;
    border: 1px solid #ebedf2;
    text-shadow: none!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button:focus {
    background: #556EE6;
    color: #fff;
    border: 1px solid #556EE6;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
}

.fc-unthemed .fc-toolbar h2 {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: .75rem;
}

.fc-unthemed .fc-axis {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.fc-unthemed th.fc-day-header {
    padding: .75rem .5rem;
    font-size: 14px;
    font-weight: 500;
}

.fc-unthemed .fc-toolbar .fc-button {
    outline: 0!important;
    height: 2rem;
    padding: 0 1.25rem;
    font-size: 14px;
}

.fc-unthemed td.fc-today {
    background: rgba(93,120,255,.025);
}

.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
    border-color: #ebedf2;
}

.fc-unthemed .fc-event .fc-content, .fc-unthemed .fc-event-dot .fc-content {
    padding: 2px;
    cursor: pointer;
    font-size: 12px;
    text-align: left;
}

.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
    border-color: #ebedf2;
}

.fc-day-grid-event .fc-content {
    white-space: inherit;
    overflow: visible;
}

.fc-event-surgeCharge {
    background: #FFE600;
    color: #000 !important;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
    border: none !important;
    border-radius: 5px;

    .fc-time,
    .fc-title {
      color: #000 !important;
    }
}

.fc-time {
  font-weight: 700 !important;
  text-transform: uppercase;
}

.fc-event-exception {
    background: #F90B0B;
    color: #fff !important;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
    border: none !important;
    border-radius: 5px;
}

.fc-event-normal {
    background: #8bbb8e;
    color: #fff !important;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
    border: none !important;
    border-radius: 5px;
}

.fc-opacity-50 {
  opacity: 0.3;
}

.fc-calendar {
    overflow-y: visible !important;
}

.fc-scroller {
   overflow-y: visible !important;
}

.fc-past {
    background-color: #f7f7f7;
}

.fc-day-number {
    font-size: 14px;
}

.option-selector {
  width: 160px;

  .ng-select {
    background: transparent !important;

    .ng-select-container {
      border-radius: 50px !important;
      height: 38px !important;


      .ng-input {
        line-height: 14px !important;
        padding-top: 6px !important;
      }
    }
  }

}

.custom-switch {
  cursor: pointer;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  height: 1.8rem;
  width: 3.3rem;    // it was 1.75rem before. Sliding way is longer than before.
  pointer-events: all;
  border-radius: 1rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 0px);
  left: calc(-2.25rem + 2px);
  width: calc(1.8rem - 4px);   // it was calc(1rem - 4px) before. Oval is bigger than before.
  height: calc(1.8rem - 4px);  // it was calc(1rem - 4px) before. Oval is bigger than before.
  background-color: #adb5bd;
  border-radius: 2rem; //   it was 0.5rem before. Oval is bigger than before.
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(1.2rem); //translateX(0.75rem);
  transform: translateX(1.5rem); //translateX(0.75rem);
}

.click-link {
  cursor: pointer;

  &:hover {
    p, h5 {
      color: #6D82E8 !important;
      font-weight: 500 !important;
    }
  }
}

.ng-select-disabled {
  .ng-value-label {
    color: #4E556C !important;
  }
}

ngb-carousel {
  box-shadow: 0px 3px 6px #00000029;
  height: 100% !important;
  //border: 2px solid #000 !important;

  &:focus {
    border: none !important;
  }
}

.carousel-control-next {
  .carousel-control-next-icon{
    padding: 10px !important;
    border-radius: 50% !important;
    background-color: #000 !important;
  }
}

.carousel-control-prev {
  .carousel-control-prev-icon{
    padding: 10px !important;
    border-radius: 50% !important;
    background-color: #000 !important;
  }
}

.simplebar-scrollbar::before {
  background-color: #6A7187 !important;
}

.image-preview-container {
  position: relative;

  lib-ngx-image-zoom {
    .ngxImageZoomContainer {
      width: 100% !important;
      height: 100% !important;

      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.fullscreen-map-view-enable {
  .map-view {
    height: 100vh !important;
  }
}

.add-item-button {
  background-color: #EEF6F9;
  padding: 30px 40px;
  cursor: pointer;
  border-radius: 10px;

  .add-content {
    display: block;
    text-align: center;
    align-items: center;

    .add-icon {
      text-align: center;
      margin-left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      border-radius: 50px;
      padding: 8px;
      width: 52px;
      height: 52px;
      font-size: 28px;
      margin-bottom: 20px;
    }

    .add-text {
      font-size: 16px;
    }
  }

  &:hover {
    background-color: #EEF6EF;

    // .add-icon {
    //   transform: scale(1.1);
    //   margin-left: 50%;
    //   transform: translateX(-50%);
    // }
  }
}



.ck .ck-editor__main> .ck-editor__editable {
  border: 1px solid #CED4DA !important;
  border-radius: 5px !important;
}
